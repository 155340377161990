<template>
  <div class="d-flex justify-content-center">
    <div class="col-12">
      <div class="row">
        <div class="col-3">
          <label for="" class="Form-label">Type: </label>
        </div>
        <div class="col-9">
          {{ media_data.asset_type }}
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <label for="" class="Form-label">Duration: </label>
        </div>
        <div class="col-9">
          {{ media_data.duration }}
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <label for="" class="Form-label">Encoding: </label>
        </div>
        <div class="col-9">
          {{ media_data.encoding }}
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <label for="" class="Form-label">Channels: </label>
        </div>
        <div class="col-9">
          {{ media_data.channels }}
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <label for="" class="Form-label">Sample Rate: </label>
        </div>
        <div class="col-9">
          {{ media_data.sample_rate }}
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <label for="" class="Form-label">Sample Precision: </label>
        </div>
        <div class="col-9">
          {{ media_data.sample_precision }}
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <label for="" class="Form-label">File Type: </label>
        </div>
        <div class="col-9">
          {{ media_data.file_type }}
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <label for="" class="Form-label">Avg Bitrate: </label>
        </div>
        <div class="col-9">
          {{ media_data.avg_bitrate }}
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <label for="" class="Form-label">File Size: </label>
        </div>
        <div class="col-9">
          {{ media_data.file_size }}
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <label for="" class="form-label">Comments: </label>
        </div>
        <div class="col-9">
          {{ media_data.comments }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, toRefs, onMounted, inject } from 'vue';
  import axios from 'axios';

  export default {
    components: {
    },

    props: {
      mediaData: {
        type: Object,
        required: true,
      },

      what: {
        type: String,
        required: true,
      },
    },

    setup(props) {
      const media_data = ref(toRefs(props.mediaData));
      const selected_indexes = ref([]);
      const indexes = ref([]);

      onMounted(async() => {
        const token = await inject('authToken');

        // Propagate indexes.
        axios.get("/media-1.0/indexes", {
          'headers': {
            'Authorization': `Bearer ${token}`
          }
        }).then(response => {
          let ret_array = [];
          response.data.forEach((name) => {
            ret_array.push({"value": name.index_name, "label": name.index_name});
          });

          indexes.value = ret_array;
        });        
      });

      return {
        media_data,
        selected_indexes,
        indexes,
      }
    },
  }
</script>

<style scoped>

</style>
