<template>
  <div class="d-flex justify-content-left mt-3">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="/">Home</a></li>
        <li class="breadcrumb-item"><a href="/index-mgmt">Index Management</a></li>
        <li v-if="index_name === ''" class="breadcrumb-item active" aria-current="page">Create Index</li>
        <li v-else class="breadcrumb-item active" aria-current="page">Edit Index</li>
      </ol>
    </nav>
  </div>

  <div class="d-flex justify-content-center">
    <h1>Index {{ (index_name == '') ? "Creation" : "Edit" }}</h1>
  </div>

  <div class="d-flex justify-content-center">
    <FlashMessage :group="flash_group" />
  </div>

  <div class="row mb-3">
    <div class="col-1">
    </div>
    <div class="col-10" :class="{'alert-danger': v$.name.$errors.length}">
      <div class="col-3">
        <label for="" class="form-label">Index Name: </label>
      </div>
      <div class="col-9">
        <input type="text" class="form-control" v-model="form.name" :disabled="index_name !== ''" />
        <span v-if="v$.name.$errors.length">Please enter an Index Name</span>
      </div>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-1">
    </div>
    <div class="col-10" :class="{'alert-danger': v$.type.$errors.length}">
      <div class="col-3">
        <label for="" class="form-label">Type: </label>
      </div>
      <div class="col-9">
        <Multiselect
          v-model="form.type"
          mode="single"
          :searchable="true"
          :options="options" />
        <span v-if="v$.type.$errors.length">Please select an Index Type</span>
      </div>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-1">
    </div>
    <div class="col-10" :class="{'alert-danger': v$.desc.$errors.length}">
      <div class="col-3">
        <label for="" class="form-label">Description: </label>
      </div>
      <div class="col-9">
        <textarea class="form-control" id="" rows="3" v-model="form.desc"></textarea>
        <span v-if="v$.desc.$errors.length">Please enter a Description</span>
      </div>
    </div>
  </div>
  
  <div class="row mb-3 align-items-end">
    <div class="col-8">
    </div>
    <div class="col">
      <a href="#" class="btn btn-primary" @click="submitForm"><fa :icon="['fas', 'save']" /> Save</a>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted, inject } from 'vue';
import Multiselect from '@vueform/multiselect';
import axios from 'axios';
import { flashMessage } from '@smartweb/vue-flash-message';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
  components: {
    Multiselect,
  },

  props: {
    index_name: {
      type: String,
      required: false,
      default: '',
    }
  },

  setup(props) {
    const name = ref('');
    const type = ref('');
    const desc = ref('');
    const options = [
      { "value": "MUSIC", "label": "Music" },
      { "value": "SPOT", "label": "Spot" }
    ];

    const form = reactive({
      name,
      type,
      desc,
    });

    const token = ref('');

    const rules = {
      name: { required },
      type: { required },
      desc: { required }
    }

    const v$ = useVuelidate(rules, form);

    const now = new Date();
    const ctime = now.getTime();

    const flash_group = `media_upload.${ctime}`;

    onMounted(async() => {
      token.value = await inject('authToken');

      if (props.index_name !== '') {
        axios.get("/media-1.0/indexes/config/" + props.index_name, {
          'headers': {
            'Authorization': `Bearer ${token.value}`
          }
        }).then(response => {
          form.name = props.index_name;
          form.desc = response.data.description;
          form.type = response.data.asset_type;
        }).catch(error => {
          flashMessage.show({
            type: "error",
            title: "",
            text: error.response.data.message,
            group: this.flash_group
          });
        })
      }
      
    });

    return {
      form,
      options,
      token,
      v$,
      flash_group
    }
  },

  methods: {
    submitForm() {
      this.v$.$touch();
      
      if (this.v$.$error) {
        return;
      }

      let end_point = "/media-1.0/indexes/config/";

      if (this.index_name !== '') {
        end_point += this.form.name;
      }

      axios.post(end_point, {
        "index_name": this.form.name,
        "asset_type": this.form.type,
        "description": this.form.desc  
      },
      {
        'headers': {
            'Authorization': `Bearer ${this.token}`
        }
      }).then(response => {
        console.log(response.data);
        
        flashMessage.show({
          type: "success",
          title: "",
          text: "Index " + this.form.name + " saved",
          group: this.flash_group
        })
      });
    }
  }
}
</script>

<style scoped>

</style>