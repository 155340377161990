<template>
  <div class="d-flex justify-content-left mt-3">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="/">Home</a></li>
        <li class="breadcrumb-item"><a href="/campaigns">Campaigns</a></li>
        <li v-if="campaign_id === ''" class="breadcrumb-item active" 
          aria-current="page">Create Campaign</li>
        <li v-else class="breadcrumb-item active" aria-current="page">Edit Campaign</li>
      </ol>
    </nav>
  </div>

  <div>
    <div class="d-flex justify-content-center">
      <h1>Campaign {{ (campaign_id == '') ? "Creation" : "Edit" }}</h1>
    </div>

    <div class="d-flex justify-content-center">
      <FlashMessage :group="flash_group" />
    </div>

    <ul class="nav nav-tabs" id="myTab" role="tablist" v-if="campaign_id !== ''">
      <li class="nav-item" role="presentation">
        <button class="nav-link active" id="campaign-tab" data-bs-toggle="tab" data-bs-target="#campaign" 
          type="button" role="tab" aria-controls="campaign" aria-selected="true">
          Campaign
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" id="rules-tab" data-bs-toggle="tab" data-bs-target="#rules"
          type="button" role="tab" aria-controls="rules" aria-selected="false">
          Rules
        </button>
      </li>
    </ul>

    <div class="tab-content">
      <div id="campaign" class="tab-pane active" role="tabpanel" aria-labelledby="campaign-tab">
        <div class="row">
          <div class="col-1">
          </div>
          <div class="col-10">
            <div class="row pt-3 mb-3" :class="{'alert-danger': v$.name.$errors.length}">
              <div class="col-3">
                <label for="name" class="form-label">Campaign Name: </label>
              </div>
              <div class="col-9">
                <input v-model="form.name" type="text" class="form-control" id="name" />
                <span v-if="v$.name.$errors.length">Campaign Name is required.</span>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-1">
          </div>
          <div class="col-10">
            <div class="row pt-3 mb-3" :class="{'alert-danger': v$.desc.$errors.length}">
              <div class="col-3">
                <label for="desc" class="form-label">Description: </label>
              </div>
              <div class="col-9">
                <input v-model="form.desc" type="text" class="form-control" id="desc" />
                <span v-if="v$.desc.$errors.length">Campaign Index is required.</span>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-1">
          </div>
          <div class="col-10">
            <div class="row pt-3 mb-3">
              <div class="col-3">
                <label for="notes" class="form-label">Notes: </label>
              </div>
              <div class="col-9">
                <textarea v-model="form.notes" id="notes" class="form-control" />
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-1">
          </div>
          <div class="col-10">
            <div class="row pt-3 mb-3">
              <div class="col-3">
                <label for="start_time" class="form-label">Start Time: </label>
              </div>
              <div class="col-9">
                <input v-model="form.start_time" type="text" class="form-control" 
                  id="start_time" placeholder="mm/dd/yyyy hh:ss" />
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-1">
          </div>
          <div class="col-10">
            <div class="row pt-3 mb-3">
              <div class="col-3">
                <label for="desc" class="form-label">End Time: </label>
              </div>
              <div class="col-9">
                <input v-model="form.end_time" type="text" class="form-control" 
                  id="end_time" placeholder="mm/dd/yyyy hh:ss" />
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-1">
          </div>
          <div class="col-10">
            <div class="row pt-3 mb-3" :class="{'alert-danger': v$.index.$errors.length}">
              <div class="col-3">
                <label for="index" class="form-label">Campaign Index: </label>
              </div>
              <div class="col-9">
                <Multiselect
                  v-model="form.index"
                  mode="single"
                  :searchable="true"
                  :options="campaign_indexes" 
                  @change="listMedia($event)" />
                <span v-if="v$.index.$errors.length">Campaign Index is required.</span>
              </div>
            </div>
          </div>
        </div>

        <div class="row pt-3 mb-3 align-items-end">
          <div class="col-11">
          </div>
          <div class="col-1">
            <a href="#" class="btn btn-primary" @click="saveCampaign()" :disable="process_campaign">
              <fa :icon="['fas', 'save']" /> Save Campaign
            </a>
          </div>
        </div>
      </div>

      <div id="rules" v-if="campaign_id !== ''" class="tab-pane" role="tabpanel" aria-labelledby="rules-tab">
        <div class="row" v-for="(spot, i) in spots" :key="i">
          <div class="col-2">
          </div>
          <div class="col-9">
            <div class="row pt-3 mb-3">
              <div class="col-8">{{ spot.original_filename }}</div>
              <div class="col-4 text-end">
                <button type="button" class="btn btn-primary" @click="openModal(spot)"><fa :icon="['fas', 'plus']" /> Add Rule</button>
              </div>
            </div>
            <div v-for="(rule, i) in form.rules.get(spot.sha256sum)" :key="i" class="row pt-3 mb-3">
              <div class="col-8">
                <div class="row">
                  <div class="col">
                    {{ rule.short_description }} 
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    {{ rule.notes }}
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    {{ rule.predicates }}
                  </div>
                </div>
              </div>
              <div class="col-4 text-end">
                <button type="button" class="btn btn-primary" @click="removeRule(spot.sha256sum, i)"><fa :icon="['fas', 'times-circle']" /> Delete Rule</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="rulesModal" tabindex="-1">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Create Rule for {{ modal.filename }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal"></button>
        </div>
        <div class="modal-body">
          <div class="row mb-2">
            <div class="col-2">
              Short Description:
            </div>
            <div class="col-10">
              <input v-model="modal.short_desc" type="text" class="form-control" />
            </div>
          </div>
          <div class="row">
            <div class="row mb-2">
              <div class="col-2">
                Notes:
              </div>
              <div class="col-10">
                <textarea v-model="modal.notes" class="form-control"></textarea>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-6 text-center">
              Selected Rule(s)
            </div>
            <div class="col-6 text-center">
              Rules Bank
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <draggable
                class="dragArea list-group"
                :list="modal_rules"
                group="rules"
                itemKey="id"
              >
                <template #item="{ element }">
                  <div>
                    <div v-if="element.what === 'DateRange'" class="list-group-item">
                      <div class="row pb-1" 
                        :class="{'alert-danger': v1$.dateStart.$errors.length || v1$.dateEnd.$errors.length || dateEndError !== ''}">
                        <div class="col">
                          Date Range: 
                        </div>
                      </div>
                      <div class="row" :class="{'alert-danger': v1$.dateStart.$errors.length || v1$.dateEnd.$errors.length || dateEndError !== ''}">
                        <div class="col">
                          From <input v-model="modal.dateStart" class="form-control" type="text" placeholder="yyyy-mm-dd" /> 
                          To <span class="small">(Optional)</span><input v-model="modal.dateEnd" class="form-control" type="text" placeholder="yyyy-mm-dd" />
                          <span class="" v-if="v1$.dateStart.$errors.length">Starting date must be in the format of yyyy-mm-dd<br /></span>
                          <span v-if="v1$.dateEnd.$errors.length">Ending date must be in the format of yyyy-mm-dd</span>
                          <span v-if="dateEndError !== ''">{{ dateEndError }}</span>
                        </div>
                      </div>
                    </div>
                    
                    <div v-if="element.what === 'EveryNHours'" class="list-group-item">
                      <div class="row pb-1" 
                        :class="{'alert-danger': v1$.everyNHours.$errors.length}">
                        <div class="col">
                          Every N Hours:
                        </div>
                      </div>
                      <div class="row" :class="{'alert-danger': v1$.everyNHours.$errors.length}">
                        <div class="col">
                          <input v-model="modal.everyNHours" type="number" class="form-control" min="0" max="23" />
                          <span v-if="v1$.everyNHours.$errors.length">Hours must be greater than 0.</span>
                        </div>
                      </div>
                    </div>
                    
                    <div v-if="element.what === 'EveryNMinutes'" class="list-group-item">
                      <div class="row pb-1" :class="{'alert-danger': v1$.everyNMinutes.$errors.length}">
                        <div class="col">
                          Every N Minutes:
                        </div>
                      </div>
                      <div class="row" :class="{'alert-danger': v1$.everyNMinutes.$errors.length}">
                        <div class="col">
                          <input v-model="modal.everyNMinutes" type="number" class="form-control" min="0" max="59" />
                          <span v-if="v1$.everyNMinutes.$errors.length">Minutes must be greater than 0.</span>
                        </div>
                      </div>
                    </div>
                    
                    <div v-if="element.what === 'TimeRange'" class="list-group-item">
                      <div class="row pb-1" 
                        :class="{'alert-danger': v1$.timeStart.$errors.length || v1$.timeEnd.$errors.length || timeEndError !== ''}">
                        <div class="col">
                          Time Range:
                        </div>
                      </div>
                      <div class="row" :class="{'alert-danger': v1$.timeStart.$errors.length || v1$.timeEnd.$errors.length || timeEndError !== ''}">
                        <div class="col">
                          From <input v-model="modal.timeStart" class="form-control" type="text" placeholder="hh:mm" /> 
                          To <span class="small">(Optional)</span><input v-model="modal.timeEnd" class="form-control" type="text" placeholder="hh:mm" />
                          <span v-if="v1$.timeStart.$errors.length">Starting time must be in the format of hh:ss</span>
                          <span v-if="v1$.timeEnd.$errors.length">Ending time must be in the format of hh:ss</span>
                          <span v-if="timeEndError !== ''">{{ timeEndError }}</span>
                        </div>
                      </div>
                    </div>

                    <div v-if="element.what === 'RepetitionsPerHour'" class="list-group-item">
                      <div class="row pb-1">
                        <div class="col">
                          Repetitions per Hour:
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <input v-model="modal.repetitions" type="number" class="form-control" />
                        </div>
                      </div>
                    </div>

                    <div v-if="element.what === 'OnMinutes'" class="list-group-item">
                      <div class="row pb-1" :class="{'alert-danger': v1$.onMinutes.$errors.length}">
                        <div class="col">
                          On Minutes:
                        </div>
                      </div>
                      <div class="row" :class="{'alert-danger': v1$.onMinutes.$errors.length}">
                        <div class="col">
                          <input v-model="modal.onMinutes" type="text" class="form-control" placeholder="min, min, min" />
                          <span v-if="v1$.onMinutes.$errors.length">On Minutes must be a list of minutes separated by a comma</span>
                        </div>
                      </div>
                    </div>

                    <div v-if="element.what === 'Probability'" class="list-group-item">
                      <div class="row pb-1">
                        <div class="col">
                          Probabaility:
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <input v-model="modal.probability" type="number" class="form-control" min="0" max="100" />
                        </div>
                      </div>
                    </div>

                    <div v-if="element.what === 'ExactTime'" class="list-group-item">
                      <div class="row pb-1" :class="{'alert-danger': v1$.exactTime.$errors.length}">
                        <div class="col">
                          Exact Time:
                        </div>
                      </div>
                      <div class="row" :class="{'alert-danger': v1$.exactTime.$errors.length}">
                        <div class="col">
                          <input v-model="modal.exactTime" type="text" class="form-control" placeholder="hh:mm" />
                          <span v-if="v1$.exactTime.$errors.length">Exact Time needs to be in the format hh:mm</span>
                        </div>
                      </div>
                    </div>

                    <div v-if="element.what === 'DaysOfWeek'" class="list-group-item">
                      <div class="row mb-1">
                        <div class="col">
                          Days of the Week:
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <input v-model="modal.sun" type="checkbox" class="btn-check" id="btnCheckSun" autocomplete="off" value="0">
                          <label class="btn btn-outline-primary" for="btnCheckSun">Sun</label>&nbsp;

                          <input v-model="modal.mon" type="checkbox" class="btn-check" id="btnCheckMon" autocomplete="off" value="1">
                          <label class="btn btn-outline-primary" for="btnCheckMon">Mon</label>&nbsp;

                          <input v-model="modal.tue" type="checkbox" class="btn-check" id="btnCheckTue" autocomplete="off" value="2">
                          <label class="btn btn-outline-primary" for="btnCheckTue">Tue</label>&nbsp;

                          <input v-model="modal.wed" type="checkbox" class="btn-check" id="btnCheckWed" autocomplete="off" value="3">
                          <label class="btn btn-outline-primary" for="btnCheckWed">Wed</label>&nbsp;

                          <input v-model="modal.thu" type="checkbox" class="btn-check" id="btnCheckThu" autocomplete="off" value="4">
                          <label class="btn btn-outline-primary" for="btnCheckThu">Thu</label>&nbsp;

                          <input v-model="modal.fri" type="checkbox" class="btn-check" id="btnCheckFri" autocomplete="off" value="5">
                          <label class="btn btn-outline-primary" for="btnCheckFri">Fri</label>&nbsp;

                          <input v-model="modal.sat" type="checkbox" class="btn-check" id="btnCheckSat" autocomplete="off" value="6">
                          <label class="btn btn-outline-primary" for="btnCheckSat">Sat</label>
                        </div>
                      </div>
                    </div>

                    <div v-if="element.what === 'RepetitionsPerTimeRange'" class="list-group-item">
                      <div class="row pb-1" :class="{'alert-danger': v1$.reps.$errors.length || v1$.repsTimeStart.$errors.length || v1$.repsTimeEnd.$errors.length || repsTimeEndError !== ''}">
                        <div class="col">
                          Repetitions Per Time Range:
                        </div>
                      </div>
                      <div class="row" :class="{'alert-danger': v1$.reps.$errors.length || v1$.repsTimeStart.$errors.length || v1$.repsTimeEnd.$errors.length || repsTimeEndError !== ''}">
                        <div class="col">
                          <input v-model="modal.reps" type="number" class="form-control" placeholder="Repetitions" />
                          From <input v-model="modal.repsTimeStart" type='text' class="form-control" placeholder="hh:mm" />
                          To <input v-model="modal.repsTimeEnd" type='text' class="form-control" placeholder="hh:mm" />
                          <span v-if="v1$.reps.$errors.length">Repetitions must be greater than 0.<br /></span>
                          <span v-if="v1$.repsTimeStart.$errors.length">Starting time must be in the format hh:mm <br /></span>
                          <span v-if="v1$.repsTimeEnd.$errors.length">Ending time must be in the format hh:mm <br /></span>
                          <span v-if="repsTimeEndError !== ''">{{ repsTimeEndError }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </draggable>
            </div>
            <div class="col-6">
              <draggable
                class="dragArea list-group"
                :list="draggable_rules"
                group="rules"
                itemKey="id"
              >
                <template #item="{ element }">
                  <div>
                    <div class="list-group-item">
                      {{ element.what }}
                    </div>
                  </div>
                </template>
              </draggable>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col text-end">
              <button type="button" class="btn btn-primary" aria-label="Save Rule(s)" @click="processRules">
                <fa :icon="['fas', 'save']" /> Save Rule(s)
              </button>
              &nbsp;
              <button type="button" class="btn btn-primary" data-bs-dismiss="modal" aria-label="Close" @click="closeModal">
                <fa :icon="['fas', 'times-circle']" /> Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, reactive, onMounted, inject } from 'vue';
  import axios from 'axios';
  import Multiselect from '@vueform/multiselect';
  import { flashMessage } from '@smartweb/vue-flash-message';
  import useVuelidate from '@vuelidate/core';
  import { required, between, minValue, helpers } from '@vuelidate/validators';
  import { Modal } from 'bootstrap';
  import draggable from "vuedraggable";

  export default {
    components: {
      Multiselect,
      draggable,
    },

    props: {
      campaign_id: {
        type: String,
        required: false,
        default: '',
      }
    },

    setup(props) {
      const modal_rules = ref([]);
      const draggable_rules = ref([
        {
          id: 0,
          what: 'DateRange',
        },
        {
          id: 1,
          what: 'EveryNHours',
        },
        {
          id: 2,
          what: 'EveryNMinutes',
        },
        {
          id: 3,
          what: 'Probability',
        },
        {
          id: 4,
          what: 'ExactTime',
        },
        {
          id: 5,
          what: 'OnMinutes',
        },
        { 
          id: 6,
          what: 'RepetitionsPerHour',
        },
        {
          id: 7,
          what: 'TimeRange',
        },
        { 
          id: 8,
          what: 'DaysOfWeek',
        },
        {
          id: 9,
          what: 'RepetitionsPerTimeRange',
        }
      ]);

      const form = reactive({
        campaign_id: '',
        rules: new Map(),
        name: '',
        desc: '',
        notes: '',
        start_time: '',
        end_time: '',
        index: '',
      });

      const modal = reactive({
        filename: '',
        sha: '',
        short_desc: '',
        notes: '',
        uuid: '',
        dateStart: '',
        dateEnd: '',
        timeStart: '',
        timeEnd: '',
        everyNHours: '',
        everyNMinutes: '',
        probability: '',
        exactTime: '',
        onMinutes: '',
        repetitionsPerHour: '',
        sun: '',
        mon: '',
        tue: '',
        wed: '',
        thu: '',
        fri: '',
        sat: '',
        reps: '',
        repsTimeStart: '',
        repsTimeEnd: '',
      });

      const campaign_indexes = ref([]);
      const spots = ref([]);
      const show_rules = reactive(new Map());
      const process_campaign = ref(false);

      const TimeMatch = helpers.regex(/^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/);
      const onMinutesMatch = helpers.regex(/^[0-5]*[0-9](,\s*[1-5]*[0-9])*$/);
      const dateRangeMatch = helpers.regex(/^(19|20)\d\d([- /.])(0[1-9]|1[012])\2(0[1-9]|[12][0-9]|3[01])$/);

      const rules = {
        name: { 
          required
        },

        desc: { 
          required
        },

        index: { 
          required
        },
      }

      const rules1 = {
        everyNHours: {
          minValue: minValue(1)
        },

        everyNMinutes: {
          minValue: minValue(1)
        },

        probability: {
          between: between(0,100)
        },

        exactTime: {
          TimeMatch
        },

        onMinutes: {
          onMinutesMatch
        },

        dateStart: {
          dateRangeMatch
        },

        dateEnd: {
          dateRangeMatch,
        },

        timeStart: {
          TimeMatch
        },

        timeEnd: {
          TimeMatch,
        },

        reps: {
          minValue: minValue(1)
        },

        repsTimeStart: {
          TimeMatch
        },

        repsTimeEnd: {
          TimeMatch
        }
      }

      const dateEndError = ref('');
      const timeEndError = ref('');
      const repsTimeEndError = ref('');

      const v$ = useVuelidate(rules, form);
      const v1$ = useVuelidate(rules1, modal);

      const now = new Date();
      const ctime = now.getTime();
      const flash_group = `config.${ctime}`;

      // Take ctime and return mm/dd/yyyy hh:mm:ss format.
      const convertCTime = ((time) => {
        const dt = new Date(time * 1000);

        return `${
          (dt.getMonth()+1).toString().padStart(2, '0')}/${
          dt.getDate().toString().padStart(2, '0')}/${
          dt.getFullYear().toString().padStart(4, '0')} ${
          dt.getHours().toString().padStart(2, '0')}:${
          dt.getMinutes().toString().padStart(2, '0')}:${
          dt.getSeconds().toString().padStart(2, '0')}`
      });

      onMounted(async() => {
        const token = await inject('authToken');

        axios.get("/media-1.0/indexes?assetType=SPOT", {
          'headers': {
            'Authorization': `Bearer ${token}`
          }
        }).then(response => {
          let ret_array = [];
          response.data.forEach((name) => {
            ret_array.push({"value": name.index_name, "label": name.index_name});
          });
          campaign_indexes.value = ret_array;
        })
    
        if (props.campaign_id !== '') {
          axios.get("/config-1.0/campaigns/" + props.campaign_id, {
              'headers': {
              'Authorization': `Bearer ${token}`
            }
          }).then(response => {
            form.campaign_id = props.campaign_id;
            form.name = response.data.name;
            form.desc = response.data.short_description;
            form.notes = response.data.notes;
            form.start_time = (response.data.start_time !== null) ? convertCTime(response.data.start_time) : "";
            form.end_time = (response.data.end_time !== null) ? convertCTime(response.data.end_time) : "";
            form.index = response.data.index;

            axios.get("/media-1.0/indexes/" + form.index, {
              'headers': {
                'Authorization': `Bearer ${token}`
              }
            }).then(response => {
              spots.value = response.data;

              axios.get("/config-1.0/campaigns/" + props.campaign_id + "/rules", {
                'headers': {
                  'Authorization': `Bearer ${token}`
                }
              }).then(response => {
                response.data.forEach((rules_obj) => {
                  axios.get("/config-1.0/campaigns/" + props.campaign_id +"/rules/" + rules_obj.rule_id, {
                    'headers': {
                      'Authorization': `Bearer ${token}`
                    }
                  }).then(response => {
                    const tmp_obj = new Object({
                      target_asset: rules_obj.target_asset,
                      short_description: response.data.short_description,
                      notes: response.data.notes,
                      predicates: response.data.predicates,
                      uuid: response.data.rule_id
                    });

                    let swp_arr = form.rules.get(rules_obj.target_asset) || [];
                    swp_arr.push(tmp_obj);
                    form.rules.set(rules_obj.target_asset, swp_arr);
                  });
                });
              });
            });
          });
        } 
      });

      return {
        modal_rules,
        draggable_rules,
        form,
        modal,
        campaign_indexes,
        spots,
        show_rules,
        process_campaign,
        flash_group,
        v$,
        v1$,
        dateEndError,
        timeEndError,
        repsTimeEndError,
        convertCTime,
      }
    },

    methods: {
      async saveCampaign() {
        this.v$.$touch();

        if (this.v$.$error) {
          return;
        }

        this.process_campaign = true;

        const token = await this.$auth.getTokenSilently();

        if (this.form.campaign_id === '') {
          this.form.campaign_id = this.genUuid();
        }

        const start_time = (this.form.start_time !== '') ? this.getCTime(this.form.start_time): null;
        const end_time = (this.form.end_time !== '') ? this.getCTime(this.form.end_time) : null;

        axios.post("/config-1.0/campaigns/" + this.form.campaign_id, {
          name: this.form.name,
          index: this.form.index,
          short_description: this.form.desc,
          notes: this.form.notes,
          start_time: start_time,
          end_time: end_time,
        },
        {
          'headers': {
            'Authorization': `Bearer ${token}`
          }
        }).then(() => {
          this.process_campaign = false;
          flashMessage.show({
            type: "success",
            title: "Save Campaign",
            text: "Campaign saved.",
            group: this.flash_group
          });
        }).catch(() => {
          flashMessage.show({
            type: "error",
            title: "Save Campaign",
            text: "There was an error saving the Campaign.",
            group: this.flash_group
          });
        });
      },

      genUuid() {  
        var uuidValue = "", k, randomValue;  
        for (k = 0; k < 32;k++) {  
          randomValue = Math.random() * 16 | 0;  
        
          if (k == 8 || k == 12 || k == 16 || k == 20) {  
            uuidValue += "-"  
          }  
          uuidValue += (k == 12 ? 4 : (k == 16 ? (randomValue & 3 | 8) : randomValue)).toString(16);  
        }  
        return uuidValue;  
      },

      async listMedia(e) {
        const token = await this.$auth.getTokenSilently();
        
        axios.get("/media-1.0/indexes/" + e, {
          'headers': {
            'Authorization': `Bearer ${token}`
          }
        }).then(response => {
          this.spots = response.data;
        });
      },

      getCTime(datetime) {
        const [date, time] = datetime.split(" ");
        const [month, day, year] = date.split("/");
        const [hours, secs] = time.split(":");

        return new Date(year, month-1, day, hours, secs).getTime() / 1000;
      },

      openModal(spot) {
        this.modal.filename = spot.original_filename;
        this.modal.sha = spot.sha256sum;

        const myModal = new Modal(document.getElementById('rulesModal'), {
          keyboard: false,
          backdrop: 'static',
        });

        myModal.toggle();
      },

      closeModal() {
        this.resetModal();
      },

      resetModal() {
        this.modal_rules.forEach((ele) => {
          this.clearValue(ele.what);
          this.draggable_rules.push(ele);
        });

        this.modal_rules = [];
      },

      async processRules() {
        this.v1$.$touch();

        if (this.v1$.$error) {
          return;
        }

        // Extra validation because I can't get vuelidate to work with this.
        if (this.modal.dateEnd !== '') {
          if (new Date(this.modal.dateEnd).getTime() < new Date(this.modal.dateStart).getTime()) {
            this.dateEndError = 'Ending Date must be greater than or equal to the Starting Date';
            return;
          }
        }

        if (this.modal.timeEnd !== '') {
          if (new Date(new Date().getMonth()+1 + "/" + new Date().getDate() + "/" + new Date().getFullYear() + " " + this.modal.timeEnd).getTime() < new Date(new Date().getMonth()+1 + "/" + new Date().getDate() + "/" + new Date().getFullYear() + " " + this.modal.timeStart).getTime()) {
            this.timeEndError = 'Ending Time must be greater than or equal to the Starting Time';
            return;
          }
        }

        if (this.modal.repsTimeEnd !== '') {
          if (new Date(new Date().getMonth()+1 + "/" + new Date().getDate() + "/" + new Date().getFullYear() + " " + this.modal.repsTimeEnd).getTime() < new Date(new Date().getMonth()+1 + "/" + new Date().getDate() + "/" + new Date().getFullYear() + " " + this.modal.repsTimeStart).getTime()) {
            this.repsTimeEndError = "Ending Time must be greater than or equal to the Starting Time";
            return;
          }
        }

        this.dateEndError = '';
        this.timeEndError = '';
        this.repsTimeEndError = '';
        let rules = [];

        this.modal_rules.forEach((rule) => {
          switch(rule.what) {
            case 'DateRange':
              rules.push({DateRange: { start_date: this.modal.dateStart, end_date: (this.modal.dateEnd !== "") ? this.modal.dateEnd : null }});
              break;
            case 'TimeRange':
              rules.push({TimeRange: { start_time: this.modal.timeStart + ":00", end_time: (this.modal.timeEnd !== "") ? this.modal.timeEnd + ":00" : null }});
              break;
            case 'DaysOfWeek':
              rules.push({DaysOfWeek: { days: this.setDays([this.modal.sun, this.modal.mon, this.modal.tue, this.modal.wed, this.modal.thu, this.modal.fri, this.modal.sat])}});
              break;
            case 'OnMinutes':
              rules.push({OnMinutes: { minutes: this.setMinutes(this.modal.onMinutes) }});
              break;
            case 'ExactTime':
              rules.push({ExactTime: { fire_time: this.modal.exactTime + ":00" }});
              break;
            case 'EveryNMinutes':
              rules.push({EveryNMinutes: { n: parseInt(this.modal.everyNMinutes, 10) }});
              break;
            case 'EveryNHours':
              rules.push({EveryNHours: { n: parseInt(this.modal.everyNHours, 10) }});
              break;
            case 'Probability':
              rules.push({Probability: { pct_probability: parseInt(this.modal.probability, 10) }});
              break;
            case 'RepetitionsPerHour':
              rules.push({RepetitionsPerHour: { repetitions: parseInt(this.modal.repetitionsPerHour, 10) }});
              break;
            case 'RepetitionsPerTimeRange':
              rules.push({RepetitionsPerTimeRange: { n: parseInt(this.modal.reps, 10), start_time: this.modal.repsTimeStart + ":00", end_time: this.modal.repsTimeEnd + ":00" }});
              break;
          }
        });

        let rules_swp = this.form.rules.get(this.modal.sha);

        if (rules_swp === undefined) {
          rules_swp = [];
        }

        const uuid = (this.modal.uuid === "") ? this.genUuid() : this.modal.uuid;

        rules_swp.push(new Object({
            'target_asset': this.modal.sha,
            'short_description': this.modal.short_desc,
            'notes': this.modal.notes,
            'uuid': uuid,
            'predicates': rules,
        }));

        this.form.rules.set(this.modal.sha, rules_swp);

        const token = await this.$auth.getTokenSilently();

        axios.post("/config-1.0/campaigns/" + this.form.campaign_id + "/rules/" + uuid, {
          target_asset: this.modal.sha,
          short_description: this.modal.short_desc,
          notes: this.modal.notes,
          predicates: rules
        },
        {
          'headers': {
            'Authorization': `Bearer ${token}`
          }
        });

        //this.closeModal();
      },

      lowerCaseFirstLetter(string) {
        return string[0].toLowerCase() + string.slice(1);
      },

      clearValue(what) {
        if (what == "DateRange") {
          this.modal.dateStart = '';
          this.modal.dateEnd = '';
        }
        else if (what == "TimeRange") {
          this.modal.timeStart = '';
          this.modal.timeEnd = '';
        }
        else if (what == "DaysOfTheWeek"){
          this.modal.sun = '';
          this.modal.mon = '';
          this.modal.tue = '';
          this.modal.wed = '';
          this.modal.thu = '';
          this.modal.fri = '';
          this.modal.sat = '';
        }
        else {
          this.modal[this.lowerCaseFirstLetter(what)] = '';
        }
      },

      setDays(week) {
        let return_arr = [];
        let day_value = 0;

        week.forEach(day => {
          if (day) {
            return_arr.push(day_value);
          }
          day_value++;
        });

        return return_arr;
      },

      setMinutes(minutes_str) {
        let arr_min = minutes_str.split(",");

        for (let i = 0; i < arr_min.length; i++) {
          arr_min[i] = parseInt(arr_min[i], 10);
        }

        return arr_min;
      },

      async removeRule(sha, pos) {
        let arr = this.form.rules.get(sha);
        let rule = arr.splice(pos, 1);
        this.form.rules.set(sha, arr);

        if (rule[0].uuid) {
          const token = await this.$auth.getTokenSilently();
          axios.delete("/config-1.0/campaigns/" + this.form.campaign_id + "/rules/" + rule[0].uuid, {
            'headers': {
              'Authorization': `Bearer ${token}`
            }
          });
        }
      }
    }
  }
</script>

<style scoped>
</style>