<template>
  <div class="d-flex justify-content-left mt-3">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="/">Home</a></li>
        <li class="breadcrumb-item active" aria-current="page">Configurations</li>
      </ol>
    </nav>
  </div>

  <div class="d-flex justify-content-center">
    <h1>Configurations</h1>
  </div>

  <div class="d-flex justify-content-center">
    <FlashMessage :group="flash_group" />
  </div>

  <div class="d-flex justify-content-center">
  </div>

  <div>
    <div class="row mb-3">
      <div class="col text-end">
        <a href="/config" class="btn btn-primary"><fa :icon="['fas', 'plus']" /> New Config</a>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Physical Mac Address</th>
              <th>Logical Device ID</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(config, i) in configs" :key="i">
              <td>{{ config.machine_id }}</td>
              <td>{{ config.logical_id }}</td>
              <td>
                <a href="#" class="btn btn-primary" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Delete Configuration" @click="deleteProfile(config.machine_id)">
                  <fa :icon="['fas', 'trash']" />
                </a>
                &nbsp;
                <a :href="'/config/' + config.machine_id" class="btn btn-primary" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Edit Configuration">
                  <fa :icon="['fas', 'edit']" />
                </a>
                                &nbsp;
                <a href="#" class="btn btn-primary" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Device Telemetry" @click="goToTelemetry(config.machine_id)">
                  <fa :icon="['fas', 'table']" />
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, inject, onMounted } from 'vue';
  import axios from 'axios';
  import { flashMessage } from '@smartweb/vue-flash-message';

  export default {
    components: {
    },
    
    setup() {
      const configs = ref([]);

      const now = new Date();
      const ctime = now.getTime();

      const flash_group = `config-index.${ctime}`;

      onMounted(async() => {
        const token = await inject('authToken');

        axios.get("/config-1.0/config/profiles?type=physical", {
          'headers': {
            'Authorization': `Bearer ${token}`
          }
        }).then(response => {
          configs.value = response.data;
        });       
      });

      return {
        configs,
        flash_group
      }
    },

    methods: {
      deleteProfile(profile_key) {
        flashMessage.show({
          type: "info",
          title: "Delete Profile " + profile_key,
          text: "Delete Profile is currently not implemented.",
          group: this.flash_group
        });
      },

      goToTelemetry(machine_id) {
        window.location = "/telemetry/" + machine_id;
      },
    }
  }
</script>

<style scoped>
</style>