<template>
  <div class="justify-content-center">
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';

  export default {
    components: {
    
    },  
    props: {

    },
    setup() {
      const foo = ref('Loading...');
      
      onMounted(() => {
      });

      return {
        foo
      }
    },
     methods: {
      // Log the user in
      login() {
        this.$auth.loginWithRedirect();
      },
      // Log the user out
      logout() {
        this.$auth.logout({
          returnTo: window.location.origin
        });
      }
    }
  }
</script>

<style scoped>

</style>