import { createApp } from 'vue'
import App from './App.vue'
import 'bootstrap'
//import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/styles/custom.scss';
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from './router'
import FlashMessage from '@smartweb/vue-flash-message'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import authConfig from "../auth_config.json"; 
import { setupAuth } from "./auth";

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

library.add(fas);

const app = createApp(App);
app.use(VueAxios, axios);
app.use(router);
app.use(FlashMessage);
app.component("fa", FontAwesomeIcon);

// Install the authentication plugin here
function callbackRedirect(appState) {
  router.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : '/'
  );
}

setupAuth(authConfig, callbackRedirect).then((auth) => {
  app.use(auth).mount('#app');
  app.provide('authToken', app.config.globalProperties.$auth.getTokenSilently());
});