<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <a class="navbar-brand" href="/"><img :src="publicPath + 'tring.png'" style="width: 75px;" /></a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link class="nav-link" to="/telemetry">Telemetry</router-link>
          </li>
          <!--
          <li class="nav-item">
            <router-link class="nav-link" to="/config-creation">Config Creation & Management</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/music-library">Music Library</router-link>
          </li>
          -->
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Management
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><router-link class="dropdown-item" to="/campaigns">Campaign Management</router-link></li>
              <li><router-link class="dropdown-item" to="/configs">Configuration Management</router-link></li>
              
              <li><router-link class="dropdown-item" to="/index-mgmt">Index Management</router-link></li>
              <!--
              <li><hr class="dropdown-divider"></li>
              <li><a class="dropdown-item" href="#">Something else here</a></li>
              -->
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="mediaLibraryDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Media Management
            </a>
            <ul class="dropdown-menu" aria-labelledby="mediaLibraryDropdown">
              <li><router-link class="dropdown-item" to="/media-library">Media Library</router-link></li>
              <li><router-link class="dropdown-item" to="/media-by-index">Media by Index</router-link></li>
              <li><router-link class="dropdown-item" to="/media-upload">Media Upload</router-link></li>
            </ul>
          </li>
          <!--
          <li class="nav-item">
            <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
          </li>
          -->
        </ul>
        <!--
        <form class="d-flex">
          <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
          <button class="btn btn-outline-success" type="submit">Search</button>
        </form>
        -->
        <div class="d-flex">
          <button class="btn btn-outline-primary" type="button" @click='logout'>Logout</button>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
  export default {

    setup() {
      const publicPath = process.env.BASE_URL;

      return {
        publicPath
      }
    },

    methods: {
      // Log the user in
      login() {
        this.$auth.loginWithRedirect();
      },
      // Log the user out
      logout() {
        this.$auth.logout({
          returnTo: window.location.origin
        });
      }
    } 
  }
</script>

<style>
  /*
  nav {
    background-color: #e3f2fd;
  }
  */
</style>