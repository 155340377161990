<template>
  <navbar v-if="$auth.isAuthenticated.value"></navbar>
  <navbar-login v-if="! $auth.isAuthenticated.value"></navbar-login>

  <div id="router-view">
    <router-view />
  </div>
</template>

<script>
  import Navbar from '@/components/Nav.vue';
  import NavbarLogin from "@/components/NavLogin.vue";

  export default {
    components: {
      Navbar,
      NavbarLogin
    },

    setup() {

      return {
      }
    },
  }
</script>

<style>
  th a {
    color: #f7931D
  }
  
  nav li a {
    color: #F7931D;
  }

  tr {
    cursor: pointer;
  }

  #app {
    font-family: Tahoma, Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*text-align: center;*/
    color: #2c3e50;
    /*margin-top: 60px;*/
    padding-left: 10px;
    padding-right: 10px;
  }

  ._vue-flash-msg-body {
    z-index: 1000;
  }

  .multiselect-tag {
    color: #000 !important;
    background-color: #F7931D !important;
    border-color: #F7931D !important;
    font-weight: 400 !important;
  }

  .multiselect-tag i:before {
    color: #000 !important;
  }

  .multiselect-option.is-selected {
    color: #000 !important;
    background-color: #F7931D !important;
    border-color: #F7931D !important;
    font-weight: 400 !important;
  }

  .multiselect-input {
    background-color: #fff;
  }
</style>
