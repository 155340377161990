<template>
  <div class="d-flex justify-content-left mt-3">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="/">Home</a></li>
        <li class="breadcrumb-item"><a href="/media-library">Media Library</a></li>
        <li class="breadcrumb-item active" aria-current="page">Media Upload</li>
      </ol>
    </nav>
  </div>

  <div class="d-flex justify-content-center">
    <h1>Media Upload</h1>
  </div>

  <div class="d-flex justify-content-center">
    <FlashMessage :group="flash_group" />
  </div>

  <div v-if="progress_infos" class="container justify-content-center">
    <div class="mb-2"
      v-for="(progressInfo, index) in progress_infos"
      :key="index"
    >
      <div class="row">
        <span>{{progressInfo.file_name}}</span><br />
        <div class="progress">
          <div class="progress-bar progress-bar-info"
            role="progressbar"
            :aria-valuenow="progressInfo.percentage"
            aria-valuemin="0"
            aria-valuemax="100"
            :style="{ width: progressInfo.percentage + '%' }"
          >
            {{progressInfo.percentage}}%
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <form @submit.prevent="handleSubmit">
    <div class="row mb-3">  
      <div class="col-1">
      </div>
      <div class="col-10" :class="{'alert-danger': show_files_error && ! first_time}">
        <div class="col-3">
          <label for="" class="form-label">Upload Media: </label>
        </div>
        <div class="col-9">
          <input type="file" accept=".mp3" class="form-control" @change="uploadFile($event)" multiple>
          <span v-if="show_files_error && ! first_time">Please choose a file to upload.</span>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-1">
      </div>
      <div class="col-10" :class="{'alert-danger': v$.chosen_media.$errors.length}">
        <div class="col-3">
          <label for="" class="form-label">Media Type: </label>
        </div>
        <div class="col-9">
          <select class="form-select" v-model="chosen_media" @change="updateIndexes">
            <option v-for="(medium, i) in media_type" :key="i" :value="medium">
              {{ medium }}
            </option>
          </select>
          <span v-if="v$.chosen_media.$errors.length > 0">Please select the Media Type.</span>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-1">
      </div>
      <div class="col-10" :class="{'alert-danger': v$.chosen_tags.$errors.length}">
        <div class="col-3">
          <label for="" class="form-label">Indexes: </label>
        </div>
        <div class="col-9">
          <Multiselect
            v-model="chosen_tags"
            mode="tags"
            :searchable="true"
            :options="indexes.data" />
          <span v-if="v$.chosen_tags.$errors.length > 0">Please select at least one Index.</span>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-1">
      </div>
      <div class="col-10 text-end">
        <button class="btn btn-primary" :disabled="submitting"><fa :icon="['fas', 'upload']" /> {{ (submitting) ? "Uploading..." : "Upload Files"}}</button>
      </div>
    </div>
  </form>
  
</template>

<script>
import { ref, reactive, onMounted, inject } from 'vue';
import { flashMessage } from '@smartweb/vue-flash-message';
import Multiselect from '@vueform/multiselect';
import axios from 'axios';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {  
  components: {
    Multiselect,
  },

  setup() {
    const files = ref([]);
    const chosen_media = ref('');
    const media_type = [
      "MUSIC",
      "SPOT",
    ];
    const chosen_tags = ref([]);
    const data = ref([]);
    const indexes = reactive({data});
    let type;

    const rules = {
      chosen_media: { required },
      chosen_tags: { required }
    };

    const v$ = useVuelidate(rules, { chosen_media, chosen_tags });

    const show_files_error = ref(true);
    const first_time = ref(true);
    const submitting = ref(false);

    const now = new Date();
    const ctime = now.getTime();

    const flash_group = `media_upload.${ctime}`;

    const progress_infos = ref([]);

    onMounted(async() => {
      const token = await inject('authToken');

      axios.get("/media-1.0/indexes", {
          'headers': {
            'Authorization': `Bearer ${token}`
          }
        }).then(response => {
          response.data.forEach(function (index) {
            indexes.data.push({ "value": index.index_name, "label": index.index_name});
          });
        });
    });
    return {
      files,
      chosen_media,
      media_type,
      chosen_tags,
      indexes,
      type,
      v$,
      show_files_error,
      first_time,
      submitting,
      flash_group,
      progress_infos
    }
  },

  methods: {
    uploadFile(e) {
      this.files.value = e.target.files;
      this.show_files_error = (this.files.value.length) ? false : true;
      this.first_time = false;

      if (this.progress_infos && this.progress_infos.length) {
        this.progress_infos.splice(0, this.progress_infos.length);
      }
    },

    async handleSubmit() {
      this.v$.$touch();
      
      if (this.v$.$error) {
        this.first_time = 0;
        return;
      }

      if (this.show_files_error) {
        this.first_time = false;
        return;
      }


      for (const i of Object.keys(this.files.value)) {
        let upload_tags_arr = [];
        let type;
        
        await this.readFileAsDataURL(this.files.value[i]).then((thisType) => { type = thisType });

        if (type == "audio/mpeg") {
          const token = await this.$auth.getTokenSilently();

          for (let tag_name of this.chosen_tags) {
            upload_tags_arr.push(tag_name);  
          }

          axios.post('/media-1.0/assets/ingest', {
            original_filename: this.files.value[i].name,
            asset_type: this.chosen_media,
            indexes: upload_tags_arr
          },
          {
            'headers': {
              'Authorization': `Bearer ${token}`
            }
          }).then(async response => {
            let code;
            await this.readFileAsBinaryString(this.files.value[i]).then((file_data) => { code = file_data });
            
            this.submitting = true;

            this.progress_infos[i] = { percentage: 0, file_name: this.files.value[i].name };

            let vm = this;

            axios.put(response.data.send_to, code, {
              onUploadProgress: function(progressEvent) {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                vm.progress_infos[i].percentage = percentCompleted;
              }
            }).then(response => {
              console.log(response.data);

              flashMessage.show({
                type: "success",
                title: "File Uploaded",
                text: `${this.files.value[i].name} was uploaded.`,
                group: this.flash_group,
              });
            }).catch(error => {
              flashMessage.show({
                type: "error",
                title: "Upload Error",
                text: error.response.data.message,
                group: this.flash_group
              });
            }).finally(() => {
              this.submitting = false;
            });
          }).catch(error => {
            flashMessage.show({
              type: "error",
              title: "Upload Error",
              text: error.response.data.message,
              group: this.flash_group
            });
          }); 
        }
        else {
          flashMessage.show({
            type: "error",
            title: "",
            text: `Skipping ${this.files.value[i].name} because it's not of type 'audio/mpeg'`,
            group: this.flash_group
          });
        }
      } 
    },
    
    async updateIndexes() {
      const token = await this.$auth.getTokenSilently();
      let vm = this;

      axios.get("/media-1.0/indexes?assetType=" + this.chosen_media, {
          'headers': {
            'Authorization': `Bearer ${token}`
          }
        }).then(response => {
          vm.chosen_tags = [];
          vm.indexes.data.splice(0, this.indexes.data.length);
          response.data.forEach(function (index) {
            vm.indexes.data.push({ "value": index.index_name, "label": index.index_name});
          });
        });
    },

    readFileAsDataURL(file) {
      const fileReader = new FileReader();
      let type;
      
      return new Promise((resolve) => { fileReader.onloadend = function(e) {
        const arr = (new Uint8Array(e.target.result)).subarray(0, 4);
        let header = "";

        for(let i = 0; i < arr.length; i++) {
          header += arr[i].toString(16);
        }

        console.log('Header is ' + header);
        
        // Check the file signature against known types
        switch(header) {
          case "4944333": 
          case "fffbe040":
          case "FFF3":
          case "FFF2":
            type = "audio/mpeg";
            break;
          default:
            type = "Unknown";
            break;
        }
        
        resolve(type);
      };

      fileReader.readAsArrayBuffer(file);
      });
    },

    readFileAsBinaryString(file) {
      const fileReader = new FileReader();

      return new Promise((resolve) => { fileReader.onloadend = function(e) {
        const code = e.target.result;

        resolve(code);
      }
      
      fileReader.readAsArrayBuffer(file);
      });
    },

  }
}
</script>

<style scoped>

</style>