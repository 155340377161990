<template>
  <div class="d-flex justify-content-left mt-3">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="/">Home</a></li>
        <li class="breadcrumb-item"><a href="/configs">Configurations</a></li>
        <li v-if="machine_id === ''" class="breadcrumb-item active" aria-current="page">Create Configuration</li>
        <li v-else class="breadcrumb-item active" aria-current="page">Edit Configuration</li>
      </ol>
    </nav>
  </div>
  
  <div class="d-flex justify-content-center">
    <h1>Configuration {{ (machine_id == '') ? "Creation" : "Edit" }}</h1>
  </div>

  <div class="d-flex justify-content-center">
    <FlashMessage :group="flash_group" />
  </div>

  <br />

  <div class="row">
    <div class="col-1">
    </div>
    <div class="col-10">
      <div class="row pt-3 mb-3" :class="{'alert-danger': v$.machine_id.$errors.length}">
        <div class="col-3">
          <label for="machine_id" class="form-label">Physical MAC Address: </label>
        </div>
        <div class="col-9">
          <input v-model="form.machine_id" type="text" class="form-control" id="machine_id">
          <span v-if="v$.machine_id.$errors.length">Machine ID is required.</span>
        </div>
      </div>

      <div class="row pt-3 mb-3" :class="{'alert-danger': v$.logical_id.$errors.length}">
        <div class="col-3">
          <label for="logical_id" class="form-label">Logical Device ID: </label>
        </div>
        <div class="col-9">
          <input v-model="form.logical_id" type="text" class="form-control" id="logical_id">
          <span v-if="v$.logical_id.$errors.length">Logical ID is required.</span>
        </div>
      </div>

      <div class="row pt-3 mb-3" :class="{'alert-danger': v$.environment.$errors.length}">
        <div class="col-3">
          <label for="environment" class="form-label">Environment: </label>
        </div>
        <div class="col-9">
          <Multiselect
            v-model="form.environment"
            mode="single"
            :searchable="true"
            :options="environments" />
          <span v-if="v$.environment.$errors.length">Please choose an environment</span>
        </div>
      </div>

      <div class="row pt-3 mb-3" :class="{'alert-danger': v$.configuration_api_base.$errors.length}">
        <div class="col-3">
          <label for="" class="form-label">Configuration API Base: </label>
        </div>
        <div class="col-9">
          <Multiselect
            v-model="form.configuration_api_base"
            mode="single"
            :searchable="true"
            :options="configuration_apis" />
          <span v-if="v$.configuration_api_base.$errors.length">Please choose a configuration API base.</span>
        </div>
      </div>

      <div class="row pt-3 mb-3" :class="{'alert-danger': v$.telemetry_api_base.$errors.length}">
        <div class="col-3">
          <label for="" class="form-label">Telemetry API Base: </label>
        </div>
        <div class="col-9">
          <Multiselect
            v-model="form.telemetry_api_base"
            mode="single"
            :searchable="true"
            :options="telemetry_apis" />
          <span v-if="v$.telemetry_api_base.$errors.length">Please choose a telemetry API base.</span>
        </div>
      </div>

      <div class="row pt-3 mb-3" :class="{'alert-danger': v$.media_api_base.$errors.length}">
        <div class="col-3">
          <label for="" class="form-label">Media API Base: </label>
        </div>
        <div class="col-9">
          <Multiselect
            v-model="form.media_api_base"
            mode="single"
            :searchable="true"
            :options="media_apis" />
            <span v-if="v$.media_api_base.$errors.length">Please choose a media API base.</span>
        </div>
      </div>

      <div class="row pt-3 mb-3" :class="{'alert-danger': v$.selected_indexes.$errors.length}">
        <div class="col-3">
          <label for="" class="form-label">Indexes: </label>
        </div>
        <div class="col-9">
          <Multiselect
            v-model="form.selected_indexes"
            mode="tags"
            :searchable="true"
            :createTag="true"
            :options="indexes" />
          <span v-if="v$.selected_indexes.$errors.length">Please choose the indexes assigned to this profile.</span>
        </div>
      </div>

      <div class="row pt-3 mb-3">
        <div class="col-3">
          <label for="" class="form-label">Campaigns: </label>
        </div>
        <div class="col-9">
          <Multiselect
            v-model="form.selected_campaigns"
            mode="tags"
            :searchable="true"
            :createTag="true"
            :options="campaigns" />
        </div>
      </div>

      <div class="row pt-3 mb-3 align-items-end">
        <div class="col-11">
        </div>
        <div class="col-1">
          <a href="#" class="btn btn-primary" @click="saveProfile()"><fa :icon="['fas', 'save']" /> Save</a>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
  import { ref, reactive, onMounted, inject } from 'vue';
  import axios from 'axios';
  import Multiselect from '@vueform/multiselect';
  import { flashMessage } from '@smartweb/vue-flash-message';
  import useVuelidate from '@vuelidate/core';
  import { required } from '@vuelidate/validators';
   
  export default {
    components: {
      Multiselect,
    },
    
    props: {
      machine_id: {
        type: String,
        required: false,
        default: '',
      }
    },

    setup(props) {
      const token = ref('');
      const configuration_apis = ref([]);
      const telemetry_apis = ref([]);
      const media_apis = ref([]);
      const environments = ref([]);
      const indexes = ref([]);
      const campaigns = ref([]);

      const form = reactive({
        machine_id: '',
        logical_id: '',
        environment: '',
        configuration_api_base: '',
        telemetry_api_base: '',
        media_api_base: '',
        selected_indexes: [],
        old_selected_indexes: [],
        selected_campaigns: [],
        old_selected_campaigns: [],
      });

      const now = new Date();
      const ctime = now.getTime();
      const flash_group = `config.${ctime}`;

      const rules = {
        machine_id: { required },
        logical_id: { required },
        environment: { required },
        configuration_api_base: { required },
        telemetry_api_base: { required },
        media_api_base: { required },
        selected_indexes: { required }
      }

      const v$ = useVuelidate(rules, form);

      onMounted(async() => {
        token.value = await inject('authToken');

        // Propagate indexes.
        axios.get("/media-1.0/indexes", {
          'headers': {
            'Authorization': `Bearer ${token.value}`
          }
        }).then(response => {
          let ret_array = [];
          response.data.forEach((name) => {
            ret_array.push({"value": name.index_name, "label": name.index_name});
          });

          indexes.value = ret_array;
        });

        // Propagate Campaigns.
        axios.get("/config-1.0/campaigns", {
          'headers': {
            'Authorization': `Bearer ${token.value}`
          }
        }).then(response => {
          let ret_array = [];

          response.data.forEach((campaign) => {
            ret_array.push({"value": campaign.campaign_id, "label": campaign.name});

            campaigns.value = ret_array;
          })
        })

        // Propagate configuration_apis
        configuration_apis.value.push({ value: 'https://appliance.prod.tringlive.com/config-1.0', label: 'https://appliance.prod.tringlive.com/config-1.0' });
        configuration_apis.value.push({ value: 'https://appliance.dev.tringlive.com/config-1.0', label: 'https://appliance.dev.tringlive.com/config-1.0' });
        configuration_apis.value.push({ value: 'https://appliance.dev.tring.spadea.tech/config-1.0', label: 'https://appliance.dev.tring.spadea.tech/config-1.0' });

        // Propagate telemetry_apis
        telemetry_apis.value.push({ value: 'https://appliance.prod.tringlive.com/telemetry-1.0', label: 'https://appliance.prod.tringlive.com/telemetry-1.0'});
        telemetry_apis.value.push({ value: 'https://appliance.dev.tringlive.com/telemetry-1.0', label: 'https://appliance.dev.tringlive.com/telemetry-1.0'});
        telemetry_apis.value.push({ value: 'https://appliance.dev.tring.spadea.tech/telemetry-1.0', label: 'https://appliance.dev.tring.spadea.tech/telemetry-1.0' });

        // Propagate media_apis
        media_apis.value.push({ value: 'https://appliance.prod.tringlive.com/media-1.0', label: 'https://appliance.prod.tringlive.com/media-1.0'});
        media_apis.value.push({ value: 'https://appliance.dev.tringlive.com/media-1.0', label: 'https://appliance.dev.tringlive.com/media-1.0'});
        media_apis.value.push({ value: 'https://appliance.dev.tring.spadea.tech/media-1.0', label: 'https://appliance.dev.tring.spadea.tech/media-1.0' });

        // Propagate enviroments
        environments.value.push({ value: 'PROD', label: 'PROD' });
        environments.value.push({ value: 'BETA', label: 'BETA' });
        environments.value.push({ value: 'TEST', label: 'TEST' });
        environments.value.push({ value: 'DEV', label: 'DEV' });

        // If we're editing an existing profile, get that profile's data
        if (props.machine_id !== '') {
          axios.get("/config-1.0/bootstrap/" + props.machine_id, {
            'headers': {
              'Authorization': `Bearer ${token.value}`
            }
          }).then(response => {
            form.machine_id = response.data.machine_id;
            form.logical_id = response.data.logical_id;
            form.configuration_api_base = response.data.config_api_base;

            axios.get("/config-1.0/config/" + form.logical_id, {
              'headers': {
                'Authorization': `Bearer ${token.value}`
              }
            }).then(response => {
              form.environment = response.data.environment;
              form.telemetry_api_base = response.data.telemetry_api_base;
              form.media_api_base = response.data.media_api_base;
            });

            axios.get("/config-1.0/config/" + form.logical_id + "/subscriptions", {
              'headers': {
                'Authorization': `Bearer ${token.value}`
              } 
            }).then(response => {
              let ret_array = [];

              response.data.forEach((i) => {
                ret_array.push(i.index_id);
              });
              form.selected_indexes = ret_array;
              form.old_selected_indexes = ret_array;
            });

            axios.get("/config-1.0/config/" + form.logical_id + "/campaigns", {
              'headers': {
                'Authorization': `Bearer ${token.value}`
              }
            }).then(response => {
              let ret_array = [];

              response.data.forEach((i) => {
                ret_array.push(i.campaign_id);
              });
              form.selected_campaigns = ret_array;
              form.old_selected_campaigns = ret_array;
            });
          });
        }
      });

      return {
        configuration_apis,
        telemetry_apis,
        media_apis,
        environments,
        form,
        indexes,
        campaigns,
        flash_group,
        v$
      }
    },

    methods: {
      async saveProfile() {
        this.v$.$touch();

        if (this.v$.$error) {
          return;
        }

        const token = await this.$auth.getTokenSilently();

        axios.post("/config-1.0/bootstrap/" + this.form.machine_id, {
          logical_id: this.form.logical_id,
          config_api_base: this.form.configuration_api_base
        },
        {
          'headers': {
            'Authorization': `Bearer ${token}`
          }
        }).then(() => {
          axios.post("/config-1.0/config/" + this.form.logical_id, {
            logical_id: this.form.logical_id,
            environment: this.form.environment,
            config_api_base: this.form.configuration_api_base,
            telemetry_api_base: this.form.telemetry_api_base,
            media_api_base: this.form.media_api_base
          },
          {
            'headers': {
              'Authorization': `Bearer ${token}`
            }
          }).then(() => {
            // Delete any indexes that were removed.
            this.form.old_selected_indexes.filter(e => !this.form.selected_indexes.includes(e)).forEach(index => {
              axios.delete("/config-1.0/config/" + this.form.logical_id + "/subscriptions/" + index, {
                'headers': {
                  'Authorization': `Bearer ${token}`
                }
              });
            });

            // Add Indexes from field.
            this.form.selected_indexes.forEach((index) => {
              axios.post("/config-1.0/config/" + this.form.logical_id + "/subscriptions/" + index, {}, {
                'headers': {
                  'Authorization': `Bearer ${token}`
                }
              });
            });

            // Delete any campaigns that were removed.
            this.form.old_selected_campaigns.filter(e=> ! this.form.selected_campaigns.includes(e)).forEach(index => {
              axios.delete("/config-1.0/config/" + this.form.logical_id + "/campaigns/" + index, {
                'headers': {
                  'Authorization': `Bearer ${token}`
                }
              });
            });

            // Add Campaigns from field.
            this.form.selected_campaigns.forEach((index) => {
              axios.post("/config-1.0/config/" + this.form.logical_id + "/campaigns/" + index, {}, {
                'headers': {
                  'Authorization': `Bearer ${token}`
                }
              });
            });

            this.form.old_selected_index = this.form.selected_indexes;
            this.form.old_selected_campaigns = this.form.selected_campaigns;
          });
        });

        flashMessage.show({
          type: "info",
          title: "Save Profile",
          text: "Profile saved.",
          group: this.flash_group
        });
      },
    },
  }
</script>

<style src="@vueform/multiselect/themes/default.css">
</style>
