<template>
  <div class="d-flex justify-content-left mt-3">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="/">Home</a></li>
        <li class="breadcrumb-item active" aria-current="page">Index Management</li>
      </ol>
    </nav>
  </div>
  
  <div class="d-flex justify-content-center">
    <h1>Index Management</h1>
  </div>
  
  <div class="d-flex justify-content-center">
    <FlashMessage :group="flash_group" />
  </div>

  <div class="row mb-3">
    <div class="col-10">
    </div>
    <div class="col-2 text-end">
      <label class="form-label">Media Type: </label>
      <select class="form-select" v-model="chosen_media">
        <option v-for="(medium, i) in media_type" :key="i" :value="medium">
          {{ medium }}
        </option>
      </select>
    </div>
  </div>

  <div>
    <div class="row mb-3">
      <div class="col text-end">
        <a href="/index" class="btn btn-primary"><fa :icon="['fas', 'plus']" /> New Index</a>&nbsp;
        <a href="#" class="btn btn-primary" @click="reloadTags"><fa :icon="['fas', 'sync-alt']" /> Reload</a>
      </div>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-12">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>
              Name
              <a href="#" @click.prevent="toggleSortIndex('index_name')">
                <fa v-if="sort_index_name == -1" :icon="['fas', 'sort']" />
                <fa v-if="sort_index_name == 0" :icon="['fas', 'sort-up']" />
                <fa v-if="sort_index_name == 1" :icon="['fas', 'sort-down']" />
              </a>
            </th>
            <th>
              Type
              <a href="#" @click.prevent="toggleSortIndex('file_type')">
                <fa v-if="sort_file_type == -1" :icon="['fas', 'sort']" />
                <fa v-if="sort_file_type == 0" :icon="['fas', 'sort-up']" />
                <fa v-if="sort_file_type == 1" :icon="['fas', 'sort-down']" />
              </a>
            </th>
            <th>
              Desc
              <a href="#" @click.prevent="toggleSortIndex('description')">
                <fa v-if="sort_description == -1" :icon="['fas', 'sort']" />
                <fa v-if="sort_description == 0" :icon="['fas', 'sort-up']" />
                <fa v-if="sort_description == 1" :icon="['fas', 'sort-down']" />
              </a>
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(index, i) in indexes.data" :key="i">
            <td>{{ index.index_name }}</td>
            <td>{{ index.asset_type }}</td>
            <td>{{ index.description }}</td>
            <td>
                <a href="#" class="btn btn-primary" data-bs-toggle="tooltip" 
                  data-bs-placement="bottom" title="Delete Index" 
                  @click="deleteIndex(index.index_name)">
                  <fa :icon="['fas', 'trash']" />
                </a>
                &nbsp;
                <a :href="'/index/' + index.index_name" class="btn btn-primary" 
                  data-bs-toggle="tooltip" data-bs-placement="bottom" 
                  title="Edit Index">
                  <fa :icon="['fas', 'edit']" />
                </a>
                &nbsp;
                <a href="#" class="btn btn-primary" data-bs-toggle="tooltip" 
                  data-bs-placement="bottom" title="Go To Media By Index"
                  @click="goToIndexMgmt(index.index_name)">
                  <fa :icon="['fas', 'external-link-alt']" />
                </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import { ref, reactive, onMounted, inject, toRaw } from 'vue';
  import axios from 'axios';
  import { flashMessage } from '@smartweb/vue-flash-message';
   
  export default {
    components: {
    },
    
    setup() {
      const data = ref('');
      const indexes = reactive({
        data
      });

      const now = new Date();
      const ctime = now.getTime();

      const flash_group = `media_upload.${ctime}`;

      const sort_index_name = ref(-1);
      const sort_file_type = ref(-1);
      const sort_description = ref(-1);
      const chosen_media = ref("ALL");
      const media_type = ref([
        'ALL',
        'MUSIC',
        'SPOT'
      ]);
      
      onMounted(async() => {
        const token = await inject('authToken');

        axios.get("/media-1.0/indexes", {
          'headers': {
            'Authorization': `Bearer ${token}`
          }
        }).then(response => {
          indexes.data = response.data;
        });      
      })

      return {
        indexes,
        flash_group,
        sort_index_name,
        sort_file_type,
        sort_description,
        chosen_media,
        media_type
      }
    },
    methods: {
      async reloadTags() {
        const token = await this.$auth.getTokenSilently();
        let uri = "/media-1.0/indexes";

        if (this.chosen_media != "ALL") {
          uri += "?assetType=" + this.chosen_media;
        }

        axios.get(uri, {
          'headers': {
            'Authorization': `Bearer ${token}`
          }
        }).then(response => {
          this.indexes.data = response.data;
        })
      },

      async deleteIndex(index_name) {
        const token = await this.$auth.getTokenSilently();

        // Delete any assets in the index
        axios.get("/media-1.0/indexes/" + index_name, {
          'headers' : {
            'Authorization': `Bearer ${token}`
          }
        }).then((response) => {
          response.data.forEach((media) => {
            axios.delete("/media-1.0/indexes/" + index_name + "/asset/" + media.sha256sum, {
              'headers': {
                'Authorization': `Bearer ${token}`
              }
            });
          });
        });

        // Delete the index itself
        axios.delete("/media-1.0/indexes/config/" + index_name, {
          'headers': {
            'Authorization': `Bearer ${token}`
          }
        }).then(() => {
          flashMessage.show({
            type: "success",
            title: "Delete " + index_name,
            text: "Index deleted.",
            group: this.flash_group
          });

          this.reloadTags();
        });
      },

      goToIndexMgmt(index_name) {
        window.location = "/media-by-index/" + index_name;
      },

      toggleSortIndex(what) {
        if (what == "index_name") {
          this.sort_file_type = -1;
          this.sort_description = -1;
          this.sort_index_name = ! this.sort_index_name;
        }
        else if (what == "file_type") {
          this.sort_index_name = -1;
          this.sort_description = -1;
          this.sort_file_type = ! this.sort_file_type;

        }
        else if (what == "description") {
          this.sort_index_name = -1;
          this.sort_file_type = -1;
          this.sort_description = ! this.sort_description;
        }

      this.sortArray(what);
      },

      sortArray(what) {
        let sort_by;

        if (what == 'index_name') {
          sort_by = 'index_name';
        }
        else if (what == 'file_type') {
          sort_by = 'file_type';
        }
        else if (what == 'description') {
          sort_by = 'description';
        }

        if (this.sort_index_name == 0 || this.sort_file_type == 0 || this.sort_description == 0) {
          toRaw(this.indexes.data).sort(this.compareValues(sort_by));
        }
        else if (this.sort_index_name == 1 || this.sort_file_type == 1 || this.sort_description == 1) {
          toRaw(this.indexes.data).sort(this.compareValues(sort_by, 'desc'));
        }
      },

      compareValues(key, order='asc') {
        return function(a, b) {
          if (! Object.prototype.hasOwnProperty.call(a, key) 
            || ! Object.prototype.hasOwnProperty.call(b, key)) {
            // property doesn't exist on either object
            return 0;
          }

          const varA = (typeof a[key] === 'string') ?
            a[key].toUpperCase() : a[key];
          const varB = (typeof b[key] === 'string') ?
            b[key].toUpperCase() : b[key];

          let comparison = 0;
        
          if (varA > varB) {
            comparison = 1;
          }
          else if (varA < varB) {
            comparison = -1;
          }
          return (
            (order == 'desc') ? (comparison * -1) : comparison
          );
        };
      }
    },
  }
</script>

<style scoped>
</style>