<template>
  <div class="d-flex justify-content-left mt-3">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="/">Home</a></li>
        <li class="breadcrumb-item active" aria-current="page">Campaigns</li>
      </ol>
    </nav>
  </div>

  <div class="d-flex justify-content-center">
    <h1>Campaigns</h1>
  </div>

  <div class="d-flex justify-content-center">
    <FlashMessage :group="flash_group" />
  </div>

  <div>
    <div class="row mb-3">
      <div class="col text-end">
        <a href="/campaign" class="btn btn-primary"><fa :icon="['fas', 'plus']" /> New Campaign</a>&nbsp;
        <a href="#" class="btn btn-primary" @click="reloadCampaigns"><fa :icon="['fas', 'sync-alt']" /> Reload</a>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Campaign Name
                <a href="#" @click.prevent="toggleSortCampaign('campaign_name')">
                  <fa v-if="sort_campaign_name == -1" :icon="['fas', 'sort']" />
                  <fa v-if="sort_campaign_name == 0" :icon="['fas', 'sort-up']" />
                  <fa v-if="sort_campaign_name == 1" :icon="['fas', 'sort-down']" />
                </a>
              </th>
              <th>Description</th>
              <th>Campaign Start
                <a href="#" @click.prevent="toggleSortCampaign('campaign_start')">
                  <fa v-if="sort_campaign_start == -1" :icon="['fas', 'sort']" />
                  <fa v-if="sort_campaign_start == 0" :icon="['fas', 'sort-up']" />
                  <fa v-if="sort_campaign_start == 1" :icon="['fas', 'sort-down']" />
                </a>
              </th>
              <th>Campaign End
                <a href="#" @click.prevent="toggleSortCampaign('campaign_end')">
                  <fa v-if="sort_campaign_end == -1" :icon="['fas', 'sort']" />
                  <fa v-if="sort_campaign_end == 0" :icon="['fas', 'sort-up']" />
                  <fa v-if="sort_campaign_end == 1" :icon="['fas', 'sort-down']" />
                </a>
              </th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(campaign, i) in campaigns" :key="i">
              <td>{{ campaign.name }}</td>
              <td>{{ campaign.short_description }}</td>
              <td>{{ formatTime(campaign.start_time) }}</td>
              <td>{{ formatTime(campaign.end_time) }}</td>
              <td>
                <a href="#" class="btn btn-primary" data-bs-toggle="tooltip" data-bs-placement="bottom" 
                  title="Delete Campaign" @click="deleteCampaign(campaign.campaign_id)">
                  <fa :icon="['fas', 'trash']" />
                </a>
                &nbsp;
                <a :href="'/campaign/' + campaign.campaign_id" class="btn btn-primary" data-bs-toggle="tooltip" 
                  data-bs-placement="bottom" title="Edit Campaign">
                  <fa :icon="['fas', 'edit']" />
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, inject, onMounted, toRaw } from 'vue';
  import axios from 'axios';
  import { flashMessage } from '@smartweb/vue-flash-message';

  export default {
    components: {

    },

    setup() {
      const now = new Date();
      const ctime = now.getTime();
      const flash_group = `campaigns.${ctime}`;
      const campaigns = ref([]);
      const sort_campaign_name = ref(-1);
      const sort_campaign_start = ref(-1);
      const sort_campaign_end = ref(-1);
      
      onMounted(async() => {
        const token = await inject('authToken');

        axios.get("/config-1.0/campaigns", {
          'headers': {
            'Authorization': `Bearer ${token}`
          }
        }).then(response => {
          campaigns.value = response.data;
        });
      });

      return {
        campaigns,
        flash_group,
        sort_campaign_name,
        sort_campaign_start,
        sort_campaign_end,
      }
    },

    methods: {
      async deleteCampaign(campaign_key) {
        const token = await this.$auth.getTokenSilently();

        axios.get("/config-1.0/campaigns/" + campaign_key + "/rules", {
          'headers': {
            'Authorization': `Bearer ${token}`
          }
        }).then(response => {
          // Remove any rules for the campaign.
          response.data.forEach(rule => {
            axios.delete("/config-1.0/campaigns/" + campaign_key + "/rules/" + rule.rule_id, {
              'headers': {
                'Authorization': `Bearer ${token}`
              }
            });
          });

          // Remove the campaign.
          axios.delete("/config-1.0/campaigns/" + campaign_key, {
            'headers': {
              'Authorization': `Bearer ${token}`
            }
          }).then(() => {
            this.reloadCampaigns();
          });

          flashMessage.show({
            type: "success",
            title: "Delete Campaign " + campaign_key,
            text: "Campaign deleted.",
            group: this.flash_group
          });
        });
      },

      formatTime(time) {
        if (time === null) {
          return; 
        }

        return new Date(time*1e3).toString();
      },

      async reloadCampaigns() {
        const token = await this.$auth.getTokenSilently();

        axios.get("/config-1.0/campaigns", {
          'headers': {
            'Authorization': `Bearer ${token}`
          }
        }).then(response => {
          this.campaigns = response.data;
        })
      },

      toggleSortCampaign(what) {
        if (what == "campaign_name") {
          this.sort_campaign_name = ! this.sort_campaign_name;
          this.sort_campaign_start = -1;
          this.sort_campaign_end = -1;
        }
        else if (what == "campaign_start") {
          this.sort_campaign_name = -1;
          this.sort_campaign_start = ! this.sort_campaign_start;
          this.sort_campaign_end = -1;
        }
        else if (what == "campaign_end") {
          this.sort_campaign_name = -1;
          this.sort_campaign_start = -1;
          this.sort_campaign_end = ! this.sort_campaign_end;
        }

        this.sortArray(what);
      },

      sortArray(what) {
        let sort_by;

        if (what == 'campaign_name') {
          sort_by = 'name';
        }
        else if (what == 'campaign_start') {
          sort_by = 'start_time';
        }
        else if (what == 'campaign_end') {
          sort_by = 'end_time';
        }

        if (this.sort_campaign_name == 0 || this.sort_campaign_start == 0 || this.sort_campaign_end == 0) {
          toRaw(this.campaigns).sort(this.compareValues(sort_by));
        }
        else if (this.sort_campaign_name == 1 || this.sort_campaign_start == 1 || this.sort_campaign_end == 1) {
          toRaw(this.campaigns).sort(this.compareValues(sort_by, 'desc'));
        }
      },

      compareValues(key, order='asc') {
        return function(a, b) {
          if (! Object.prototype.hasOwnProperty.call(a, key) 
            || ! Object.prototype.hasOwnProperty.call(b, key)) {
            // property doesn't exist on either object
            return 0;
          }

          const varA = (typeof a[key] === 'string') ?
            a[key].toUpperCase() : a[key];
          const varB = (typeof b[key] === 'string') ?
            b[key].toUpperCase() : b[key];

          let comparison = 0;
        
          if (varA > varB) {
            comparison = 1;
          }
          else if (varA < varB) {
            comparison = -1;
          }
          return (
            (order == 'desc') ? (comparison * -1) : comparison
          );
        };
      }
    }
  }
</script>

<style scoped>

</style>