<template>
  <div class="col-12">
    <div class="row">
      <div class="col">
        <input type="checkbox" class="form-check">
      </div>
      <div class="col-3">
        {{ days[day] }}: 
      </div>
      <div class="col">
        From
      </div>
      <div class="col-2">
        <input type="number" class="form-control" min="0" max="2400" id="" />
      </div>
      <div class="col">
        To 
      </div>
      <div class="col-2">
        <input type="number" class="form-control" min="0" max="2400" id="" />
      </div>
    </div>
    
  </div>
</template>

<script>
  import { ref } from 'vue';
   
  export default {
    components: {
    },
    
    props: {
      day: {
        Number,
      },
    },

    setup() {
      const foo = ref('Loading...');
      const days = [
        '',
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
      ];

      return {
        foo,
        days
      }
    },
  }
</script>

<style scoped>
</style>