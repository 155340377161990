<template>
  <div class="d-flex justify-content-left mt-3">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="/">Home</a></li>
        <li class="breadcrumb-item"><a href="/configs">Configurations</a></li>
        <li class="breadcrumb-item active" aria-current="page">Create Configuration</li>
      </ol>
    </nav>
  </div>
  <div class="d-flex justify-content-center">
    <h1>Config Creation</h1>
  </div>
  <div class="d-flex justify-content-center">
    <FlashMessage :group="flash_group" />
  </div>

  <br />

  <div class="row">
    <div class="col-1">
    </div>
    <div class="col-10">
      <div class="row mb-3">
        <div class="col-3">
          <label for="" class="form-label">Physical MAC Address: </label>
        </div>
        <div class="col-9">
          <input type="" class="form-control" id="">
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-3">
          <label for="" class="form-label">Logical Device ID: </label>
        </div>
        <div class="col-9">
          <input type="" class="form-control" id="">
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-3">
          <label for="" class="form-label">IP Address: </label>
        </div>
        <div class="col-9">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="" id="" value="dhcp">
            <label class="form-check-label" for="exampleRadios1">
              DHCP
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="" id="" value="static">
            <label class="form-check-label" for="exampleRadios2">
              Static
            </label>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-3">
          <label for="" class="form-label">Baseline Music Volume: </label>
        </div>
        <div class="col-9">
          <input type="number" class="form-control" min="1" max="10" id="">
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-3">
          <label for="" class="form-label">Baseline Spot Volume: </label>
        </div>
        <div class="col-9">
          <input type="number" class="form-control" min="1" max="10" id="">
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-3">
          <label for="" class="form-label">Broadcast Start and Stop: </label>
        </div>
        <div class="col-9">
          <broadcast-times v-for="i in 7" :key="i" :day="i"></broadcast-times>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-3">
          <label for="" class="form-label">Update Start and Stop: </label>
        </div>
        <div class="col-9">
          <broadcast-times v-for="i in 7" :key="i" :day="i"></broadcast-times>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-3">
          <label for="" class="form-label">Cycle-Length Assignment: </label>
        </div>
        <div class="col-9">
          <input type="number" class="form-control" id="" />
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-3">
          <label for="" class="form-label">Block-Quantity Assignment: </label>
        </div>
        <div class="col-9">
          <input type="number" class="form-control" id="" />
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-3">
          <label for="" class="form-label">Block-Duration Assignment: </label>
        </div>
        <div class="col-9">
          <input type="number" class="form-control" id="" />
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-3">
          <label for="" class="form-label">Timezone: </label>
        </div>
        <div class="col-9">
          <Multiselect
            v-model="timezone"
            mode="single"
            :searchable="true"
            :options="timezone_names" />
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-3">
          <label for="" class="form-label">Group Assignments: </label>
        </div>
        <div class="col-9">
          <Multiselect
            v-model="group_assignments"
            mode="tags"
            :searchable="true"
            :createTag="true"
            :options="groups" />
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-3">
          <label for="" class="form-label">Music Profiles: </label>
        </div>
        <div class="col-9">
          <Multiselect
            v-model="selected_music_profiles"
            mode="tags"
            :searchable="true"
            :createTag="true"
            :options="music_profiles" />
        </div>
      </div>
      <div class="row mb-3 align-items-end">
        <div class="col-11">
        </div>
        <div class="col-1">
          <a href="#" class="btn btn-primary" @click="saveProfile()">Save</a>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
  import { ref, onMounted } from 'vue';
  import BroadcastTimes from '@/components/BroadcastTimes.vue';
  import moment from 'moment-timezone';
  import Multiselect from '@vueform/multiselect';
  import { flashMessage } from '@smartweb/vue-flash-message';
   
  export default {
    components: {
      BroadcastTimes,
      Multiselect,
    },
    
    setup() {
      const foo = ref('Loading...');
      
      const time_zones = ((name) => {
        return moment.tz(name).format('ZZ');
      });

      const timezone_names = moment.tz.names(); 
      
      // Sort timezone names numerically by their UTC offset.
      timezone_names.sort((function (a, b) {
        if (Number(time_zones(a)) > Number(time_zones(b))) {
          return 1;
        }
        if (Number(time_zones(a)) < Number(time_zones(b))) {
          return -1;
        }
        return 0;
      }));

      // Add timezone offsets to name.
      timezone_names.forEach(function (name, index){
        this[index] = name + " " + time_zones(name);
      }, timezone_names);

      const groups = [
        'Rock',
        'Alt',
        'Pop',
      ];
      const music_profiles = [
        'Profile 1',
        'Profile 2',
        'Profile 3',
      ];
      const group_assignments = ref([]);
      const selected_music_profiles = ref([]);
      const timezone = ref('');

      const now = new Date();
      const ctime = now.getTime();

      const flash_group = `config_future.${ctime}`;

      onMounted(() => {
        flashMessage.show({
          type: "error",
          title: "Title",
          text: "This is a message",
          group: flash_group
        });
      });

      return {
        foo,
        timezone_names,
        time_zones,
        groups,
        music_profiles,
        group_assignments,
        selected_music_profiles,
        timezone,
        flash_group
      }
    },
    methods: {
      returnTimeZones() {
        let return_array =[];
        
        moment.tz.names.forEach((name) =>  {
          return_array.push(name);
        });

        return return_array;
      },
      saveProfile() {
        flashMessage.show({
          type: "info",
          title: "Save Profile",
          text: "Save Profile is currently not implemented.",
          group: this.flash_group
        });
      },
    },
  }
</script>

<style src="@vueform/multiselect/themes/default.css">
</style>