<template>
  <div class="d-flex justify-content-left mt-3">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="/">Home</a></li>
        <li class="breadcrumb-item"><a href="/media-library">Media Library</a></li>
        <li class="breadcrumb-item active" aria-current="page">Media By Index</li>
      </ol>
    </nav>
  </div>
  <div class='d-flex justify-content-center'>
    <h1>Media By Index</h1>
  </div>
  <div>
    <div class="row mb-3">
      <div class="col-10"></div>
      <div class="col">
        Select Index: 
        <Multiselect
          v-model="selected_index"
          mode="single"
          :searchable="true"
          :options="indexes.data" 
          @change="onChange($event)" />
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>
                Media File 
                <a href="#" @click.prevent="toggleSortMedia('files')">
                  <fa v-if="sort_files == -1" :icon="['fas', 'sort']" />
                   <fa v-if="sort_files == 0" :icon="['fas', 'sort-up']" />
                  <fa v-if="sort_files == 1" :icon="['fas', 'sort-down']" />
                </a>
              </th>
              <th>File Type
                <a href="#" @click.prevent="toggleSortMedia('file_type')">
                  <fa v-if="sort_file_type == -1" :icon="['fas', 'sort']" />
                   <fa v-if="sort_file_type == 0" :icon="['fas', 'sort-up']" />
                  <fa v-if="sort_file_type == 1" :icon="['fas', 'sort-down']" />
                </a>
              </th>
              <th>Duration
                <a href="#" @click.prevent="toggleSortMedia('duration')">
                  <fa v-if="sort_duration == -1" :icon="['fas', 'sort']" />
                   <fa v-if="sort_duration == 0" :icon="['fas', 'sort-up']" />
                  <fa v-if="sort_duration == 1" :icon="['fas', 'sort-down']" />
                </a>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(file, i) in media_list" :key="i" @click="openModal(file.sha256sum)">
              <td>{{ file.original_filename }}</td>
              <td>{{ file.file_type }}</td>
              <td>{{ file.duration }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="modal fade" id="mediaModal" tabindex="-1">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Information for {{ media.data1.original_filename }} ({{ media.data1.sha256sum }})</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="d-flex justify-content-center">
          <div class="col-12">
            <div class="row">
              <div class="col-3">
                <label for="" class="Form-label">Type: </label>
              </div>
              <div class="col-9">
                {{ media.data1.asset_type }}
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <label for="" class="Form-label">Duration: </label>
              </div>
              <div class="col-9">
                {{ media_stats.data2.duration }}
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <label for="" class="Form-label">Encoding: </label>
              </div>
              <div class="col-9">
                {{ media_stats.data2.encoding }}
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <label for="" class="Form-label">Channels: </label>
              </div>
              <div class="col-9">
                {{ media_stats.data2.channels }}
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <label for="" class="Form-label">Sample Rate: </label>
              </div>
              <div class="col-9">
                {{ media_stats.data2.sample_rate }}
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <label for="" class="Form-label">Sample Precision: </label>
              </div>
              <div class="col-9">
                {{ media_stats.data2.sample_precision }}
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <label for="" class="Form-label">File Type: </label>
              </div>
              <div class="col-9">
                {{ media_stats.data2.file_type }}
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <label for="" class="Form-label">Avg Bitrate: </label>
              </div>
              <div class="col-9">
                {{ media_stats.data2.avg_bitrate }}
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <label for="" class="Form-label">File Size: </label>
              </div>
              <div class="col-9">
                {{ media_stats.data2.file_size }}
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <label for="" class="Form-label">Comments: </label>
              </div>
              <div class="col-9">
                {{ media_stats.data2.comments }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { ref, reactive, onMounted, inject, toRaw } from 'vue';
import axios from 'axios';
import { Modal } from 'bootstrap';
import Multiselect from '@vueform/multiselect';

export default {
  components: {
      Multiselect,
    },

  props: {
    index_name: {
      type: String,
      required: false,
      default: '',
    }
  },

  setup(props) {
    // Not sure why this works with the reactive variables, but it works.
    const data = ref([]);
    const data1 = ref('');
    const data2 = ref('');

    const indexes = reactive({data});
    const selected_index = ref('');
    const media = reactive({data1});
    const media_stats = reactive({data2});
    const media_list = ref([]);
    const sort_files = ref(-1);
    const sort_file_type = ref(-1);
    const sort_duration = ref(-1);

    onMounted(async() => {
      const token = await inject('authToken');

      axios.get("/media-1.0/indexes", {
          'headers': {
            'Authorization': `Bearer ${token}`
          }
        }).then(response => {
          let ret_array = [];
          response.data.forEach((name) => {
            ret_array.push({"value": name.index_name, "label": name.index_name});
          });
          indexes.data = ret_array;

          if (props.index_name !== '') {
            selected_index.value = props.index_name;

            axios.get("/media-1.0/indexes/" + props.index_name, {
              'headers': {
                'Authorization': `Bearer ${token}`
              }
            }).then(response => {
              media_list.value = response.data;
            });
          }
        });
    });

    return {
      media_list,
      indexes,
      selected_index,
      media_stats,
      media,
      sort_files,
      sort_file_type,
      sort_duration
    }
  },

  methods: {
    async onChange(e) {
      const token = await this.$auth.getTokenSilently();

      axios.get("/media-1.0/indexes/" + e, {
        'headers': {
          'Authorization': `Bearer ${token}`
        }
      }).then(response => {
        this.media_list = response.data;
      });
    },

    async openModal(sha) {
      const token = await this.$auth.getTokenSilently();

      await axios.get("/media-1.0/assets/" + sha, {
        'headers': {
          'Authorization': `Bearer ${token}`
        }
      }).then(response => {
        this.media.data1 = response.data;
      });

      await axios.get("/media-1.0/assets/stats/" + sha, {
        'headers': {
          'Authorization': `Bearer ${token}`
        }
      }).then(response => {
        this.media_stats.data2 = response.data;
      });

      const myModal = new Modal(document.getElementById('mediaModal'), {
        keyboard: false
      });

      myModal.toggle();
    },

    toggleSortMedia(what) {
      if (what == "files") {
        this.sort_file_type = -1;
        this.sort_duration = -1;
        this.sort_files = ! this.sort_files;
      }
      else if (what == "file_type") {
        this.sort_files = -1;
        this.sort_duration = -1;
        this.sort_file_type = ! this.sort_file_type;

      }
      else if (what == "duration") {
        this.sort_files = -1;
        this.sort_file_type = -1;
        this.sort_duration = ! this.sort_duration;
      }

      this.sortArray(what);
    },

    sortArray(what) {
      let sort_by;

      if (what == 'files') {
        sort_by = 'original_filename';
      }
      else if (what == 'file_type') {
        sort_by = 'file_type';
      }
      else if (what == 'duration') {
        sort_by = 'duration';
      }

      if (this.sort_files == 0 || this.sort_file_type == 0 || this.sort_duration == 0) {
        toRaw(this.media_list).sort(this.compareValues(sort_by));
      }
      else if (this.sort_files == 1 || this.sort_file_type == 1 || this.sort_duration == 1) {
        toRaw(this.media_list).sort(this.compareValues(sort_by, 'desc'));
      }
    },

    compareValues(key, order='asc') {
      return function(a, b) {
        if (! Object.prototype.hasOwnProperty.call(a, key) 
          || ! Object.prototype.hasOwnProperty.call(b, key)) {
          // property doesn't exist on either object
          return 0;
        }

        const varA = (typeof a[key] === 'string') ?
          a[key].toUpperCase() : a[key];
        const varB = (typeof b[key] === 'string') ?
          b[key].toUpperCase() : b[key];

        let comparison = 0;
      
        if (varA > varB) {
          comparison = 1;
        }
        else if (varA < varB) {
          comparison = -1;
        }
        return (
          (order == 'desc') ? (comparison * -1) : comparison
        );
      };
    }
  }
}
</script>

<style scoped>

</style>